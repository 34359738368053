import React from 'react'
import styled from '@emotion/styled';
import WikiTooltip from '../WikiTooltip';
import MaterialIcon from '../MaterialIcon'
import Masonry from 'react-masonry-component'

import {
    Container,
    HeaderImage,
    Content,
    Heading,
    Desc,
    Nav,
    NavItem,
    Biogram,
    ArtistImage,
    WikiIcon,
    VideoSection,
    NavItemLink,
    HeaderImageWrapper
} from '../StylePage'
const constructivismColor = [132, 129, 122]
export default props => (
   <>
   <div className="slide">
   <Container columns="1fr 3fr">
        <HeaderImageWrapper>
        <HeaderImage color={constructivismColor}>
            <img  src="https://www.historiasztuki.com.pl/ilustracje/RZEZBA/MOD-Gabo-Glowa.jpg" />
        </HeaderImage>
        </HeaderImageWrapper>
        <Content>
        <Heading color={constructivismColor}>Konstruktywizm</Heading>
        <Desc>
        Konstruktywizm był skrajnie estetyzującym kierunkiem abstrakcjonistycznym tworzącym z najprostszych elementów (kwadrat, koło, trójkąt, linia prosta) dzieło oddziaływające na widza przez napięcia wynikające z relacji między tymi elementami. Istotą dzieła nie były więc same elementy, ale właśnie relacje między nimi, a istotą twórczości komponowanie, czyli konstruowanie dzieła - stąd wzieła się nawa kierunku.
Tak sformułowane założenia definiują sztukę wyrafinowaną, wymagającą od odbiorcy specjalnego rodzaju wrażliwości (a więc elitarną). Jednak w okresie powstawania konstruktywizm wymagał również całkowitej zmiany wytworzonych przez stulecia nawyków rozumienia funkcji sztuki. Pod tym względem należał więc do tych nurtów sztuki nowoczesnej, które postulowały zerwanie z tradycją.
        </Desc>
        <Nav>
           
            <NavItem color={constructivismColor} onClick={() => props.fullpageApi.moveTo(8, 1)}>
                <div>
                    <MaterialIcon icon="person" />
                </div>
                <span>Vladimir Tatlin</span>
            </NavItem>
            <NavItem color={constructivismColor} onClick={() => props.fullpageApi.moveTo(8, 2)}>
                <div>
                    <MaterialIcon icon="person" />
                </div>
                <span>Katarzyna Kobro</span>
            </NavItem>
            <NavItemLink color={constructivismColor} href="https://pl.wikipedia.org/wiki/Konstruktywizm_(sztuka)" target="_blank" rel="noopener norefferer">
          
                <div>
                    <WikiIcon />
                </div>
                <span>Wikipedia</span>
              
            </NavItemLink>
            
          
        </Nav>
        </Content>
    </Container>
   </div>
   <div className="slide">
   <Container columns="1fr 3fr">
        
        <div>
        <Biogram>
            <img src="https://uploads8.wikiart.org/temp/79d6b642-ded7-475d-b16a-cb2921d12fb5.jpg!Portrait.jpg"/>
            <div>
            <h3>Vladimir Tatlin</h3>
            <h5>1885-1953</h5>
            </div>
        </Biogram>
        </div>
        <Masonry>
        <ArtistImage width="30%">
            <img src="https://uploads3.wikiart.org/images/vladimir-tatlin/monument-to-commemorate-the-third-international-1920.jpg!Large.jpg" />
            <div>
                <h5>Monument to commemorate the Third International</h5>
                <span>1919-1920</span>
            </div>
        </ArtistImage>
     <ArtistImage width="30%">
            <img src="https://uploads6.wikiart.org/images/vladimir-tatlin/tatlin-relief-2-1914.jpg" />
            <div>
                <h5>Tatlin Relief 2</h5>
                <span>1914</span>
            </div>
        </ArtistImage>
     
     <ArtistImage width="30%">
            <img src="https://uploads0.wikiart.org/images/vladimir-tatlin/letatlin-1932.jpg!Large.jpg" />
            <div>
                <h5>Letatlin</h5>
                <span>1930-1932</span>
            </div>
        </ArtistImage>
    
     <ArtistImage width="30%">
            <img src="https://uploads1.wikiart.org/images/vladimir-tatlin/counter-relief.jpg!Large.jpg" />
            <div>
                <h5>Counter relief</h5>
                <span>?</span>
            </div>
        </ArtistImage>
       
            </Masonry>
    </Container>
   </div>
   <div className="slide">
   <Container columns="1fr 3fr">
        
        <div>
        <Biogram>
            <img src="https://uploads7.wikiart.org/images/katarzyna-kobro.jpg!Portrait.jpg"/>
            <div>
            <h3>Katarzyna Kobro</h3>
            <h5>198-1951</h5>
            </div>
        </Biogram>
        </div>
        <Masonry>
    
     <ArtistImage width="20%">
            <img src="https://uploads1.wikiart.org/images/katarzyna-kobro/rze-ba-przestrzenna-1925.jpg" />
            <div>
                <h5>Rzeźba Przestrzenna</h5>
                <span>1925</span>
            </div>
        </ArtistImage>
        <ArtistImage width="20%">
            <img src="https://uploads7.wikiart.org/images/katarzyna-kobro/kompozycja-przestrzenna-i-1925.jpg" />
            <div>
                <h5>Kompozycja Przestrzenna I</h5>
                <span>1925</span>
            </div>
        </ArtistImage>
     
     <ArtistImage width="20%">
            <img src="https://uploads3.wikiart.org/images/katarzyna-kobro/rze-ba-abstrakcyjna-i-1924.jpg" />
            <div>
                <h5>Rzeźba Abstrakcyjna I</h5>
                <span>1924</span>
            </div>
        </ArtistImage>
        <ArtistImage width="20%">
            <img src="https://uploads5.wikiart.org/images/katarzyna-kobro/rze-ba-abstrakcyjna-ii-1924.jpg!Large.jpg" />
            <div>
                <h5>Rzeźba Abstrakcyjna II</h5>
                <span>1924</span>
            </div>
        </ArtistImage>
        <ArtistImage width="20%">
            <img src="https://uploads4.wikiart.org/images/katarzyna-kobro/spatial-composition-9-1933.jpg!Large.jpg" />
            <div>
                <h5>Spatial Composition 9</h5>
                <span>1933</span>
            </div>
        </ArtistImage>
        <ArtistImage width="20%">
            <img src="https://uploads7.wikiart.org/images/katarzyna-kobro/spatial-composition-nr-6-1931.jpg!Large.jpg" />
            <div>
                <h5>Spatial Composition Nr. 6</h5>
                <span>1931</span>
            </div>
        </ArtistImage>
      
            </Masonry>
    </Container>
   </div>

   </>
)