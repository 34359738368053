import React from 'react'
import styled from '@emotion/styled';
import WikiTooltip from '../WikiTooltip';
import MaterialIcon from '../MaterialIcon'
import Masonry from 'react-masonry-component'

import {
    Container,
    HeaderImage,
    Content,
    Heading,
    Desc,
    Nav,
    NavItem,
    Biogram,
    ArtistImage,
    WikiIcon,
    VideoSection,
    NavItemLink,
    HeaderImageWrapper
} from '../StylePage'
const minimalismColor = [255, 121, 63]
export default props => (
   <>
   <div className="slide">
   <Container columns="1fr 3fr">
        <HeaderImageWrapper>
        <HeaderImage color={minimalismColor}>
            <img  src="https://www.historiasztuki.com.pl/ilustracje/RZEZBA/CON-Judd-71.jpg" />
        </HeaderImage>
        </HeaderImageWrapper>
        <Content>
        <Heading color={minimalismColor}>Minimalizm</Heading>
        <Desc>
        Minimalizm był reakcją na impulsywny <WikiTooltip query="Ekspresjonizm (sztuka)">ekspresjonizm</WikiTooltip> i ironiczny, ale głeboko zaangażowany społecznie pop-art. 
        Poszukiwał obiektywnego, pozbawionego literackich skojarzeń języka sztuki. Stosował uproszczone, wolne od metafor formy, 
        neutralne, a-emocjonalne faktury, nie przetworzone materiały pochodzenia przemysłowego, oraz pewne schematy kompozycyjne naśladujące bezosobową produkcję przemysłową - seryjność, techniki spawalnicze itp.
        <br />
        Minimalizm był nurtem, w którym skrajnie oszczędna forma wynikała z niezwykle rozbudowanej teorii, czego szczególnym przykładem jest muzyczna twórczość <WikiTooltip query="John Cage">Johna Cage'a</WikiTooltip>.

        </Desc>
        <Nav>
           
            <NavItem color={minimalismColor} onClick={() => props.fullpageApi.moveTo(4, 1)}>
                <div>
                    <MaterialIcon icon="person" />
                </div>
                <span>Tony Smith</span>
            </NavItem>
            <NavItem color={minimalismColor} onClick={() => props.fullpageApi.moveTo(4, 3)}>
                <div>
                <MaterialIcon icon="movie" />
                </div>
                <span>O minimaliźmie</span>
            </NavItem>
            <NavItem color={minimalismColor} onClick={() => props.fullpageApi.moveTo(4, 2)}>
                <div>
                    <MaterialIcon icon="person" />
                </div>
                <span>Anne Truitt</span>
            </NavItem>
            <NavItemLink color={minimalismColor} href="https://pl.wikipedia.org/wiki/Minimalizm_(sztuki_plastyczne)" target="_blank" rel="noopener norefferer">
          
                <div>
                    <WikiIcon />
                </div>
                <span>Wikipedia</span>
              
            </NavItemLink>
            
          
        </Nav>
        </Content>
    </Container>
   </div>
   <div className="slide">
   <Container columns="1fr 3fr">
        
        <div>
        <Biogram>
            <img src="https://uploads8.wikiart.org/temp/8153ba6e-b00f-4b71-bdb1-703808ea29a4.jpg!Portrait.jpg"/>
            <div>
            <h3>Tony Smith</h3>
            <h5>1912-1980</h5>
            </div>
        </Biogram>
        </div>
        <Masonry>
    
     <ArtistImage width="20%">
            <img src="https://uploads7.wikiart.org/images/tony-smith/tau-1962.jpg!PinterestSmall.jpg" />
            <div>
                <h5>Tau</h5>
                <span>1962</span>
            </div>
        </ArtistImage>
     
     <ArtistImage width="20%">
            <img src="https://uploads4.wikiart.org/images/tony-smith/the-elevens-are-up-1963.jpg!PinterestSmall.jpg" />
            <div>
                <h5>The Elevens Are Up</h5>
                <span>1963</span>
            </div>
        </ArtistImage>
    
     <ArtistImage width="20%">
            <img src="https://uploads2.wikiart.org/images/tony-smith/moondog-1964.jpg!PinterestSmall.jpg" />
            <div>
                <h5>Moondog</h5>
                <span>1964</span>
            </div>
        </ArtistImage>
        <ArtistImage width="20%">
            <img src="https://uploads1.wikiart.org/images/tony-smith/source-1967.jpg!PinterestSmall.jpg" />
            <div>
                <h5>Source</h5>
                <span>1967</span>
            </div>
        </ArtistImage>
        <ArtistImage width="20%">
            <img src="https://uploads5.wikiart.org/images/tony-smith/asteriskos-1968.jpg!PinterestSmall.jpg" />
            <div>
                <h5>Asteriskos</h5>
                <span>1968</span>
            </div>
        </ArtistImage>
        <ArtistImage width="20%">
            <img src="https://uploads7.wikiart.org/images/tony-smith/die-1968.jpg!PinterestSmall.jpg" />
            <div>
                <h5>Die</h5>
                <span>1968</span>
            </div>
        </ArtistImage>
        <ArtistImage width="20%">
            <img src="https://uploads5.wikiart.org/images/tony-smith/gracehoper-1962.jpg!PinterestSmall.jpg" />
            <div>
                <h5>Gracehoper</h5>
                <span>1962</span>
            </div>
        </ArtistImage>
        <ArtistImage width="20%">
            <img src="https://uploads4.wikiart.org/images/tony-smith/smoke.jpg!PinterestSmall.jpg" />
            <div>
                <h5>Smoke</h5>
                <span>?</span>
            </div>
        </ArtistImage>
            </Masonry>
    </Container>
   </div>
   <div className="slide">
   <Container columns="1fr 3fr">
        
        <div>
        <Biogram>
            <img src="https://uploads8.wikiart.org/temp/33ad9a31-9e8c-4312-aaa6-493086c51786.jpg!Portrait.jpg"/>
            <div>
            <h3>Anne Truitt</h3>
            <h5>1921-2004</h5>
            </div>
        </Biogram>
        </div>
        <Masonry>
    
     <ArtistImage width="20%">
            <img src="https://uploads1.wikiart.org/images/anne-truitt/first-1961.jpg!PinterestSmall.jpg" />
            <div>
                <h5>First</h5>
                <span>1961</span>
            </div>
        </ArtistImage>
     
     <ArtistImage width="20%">
            <img src="https://uploads5.wikiart.org/images/anne-truitt/bloomsday-1962.jpg!PinterestSmall.jpg" />
            <div>
                <h5>Bloomsday</h5>
                <span>1962</span>
            </div>
        </ArtistImage>
        <ArtistImage width="20%">
            <img src="https://uploads2.wikiart.org/images/anne-truitt/signal-1965.jpg!PinterestSmall.jpg" />
            <div>
                <h5>Signal</h5>
                <span>1965</span>
            </div>
        </ArtistImage>
        <ArtistImage width="20%">
            <img src="https://uploads1.wikiart.org/images/anne-truitt/southern-elegy-1962.jpg!PinterestSmall.jpg" />
            <div>
                <h5>Southern Elegy</h5>
                <span>1962</span>
            </div>
        </ArtistImage>
        <ArtistImage width="20%">
            <img src="https://uploads6.wikiart.org/images/anne-truitt/sea-garden-1964.jpg!PinterestSmall.jpg" />
            <div>
                <h5>Sea Garden</h5>
                <span>1964</span>
            </div>
        </ArtistImage>
        <ArtistImage width="20%">
            <img src="https://uploads8.wikiart.org/images/anne-truitt/spanish-main-1963.jpg!PinterestSmall.jpg" />
            <div>
                <h5>Spanish Main</h5>
                <span>1963</span>
            </div>
        </ArtistImage>
      
        <ArtistImage width="20%">
            <img src="https://uploads8.wikiart.org/images/anne-truitt/valley-forge-1963.jpg!PinterestSmall.jpg" />
            <div>
                <h5>Valley Forge</h5>
                <span>1963</span>
            </div>
        </ArtistImage>
        <ArtistImage width="20%">
            <img src="https://uploads0.wikiart.org/images/anne-truitt/pith-1969.jpg!PinterestSmall.jpg" />
            <div>
                <h5>Pith</h5>
                <span>1969</span>
            </div>
        </ArtistImage>
            </Masonry>
    </Container>
   </div>
   <div className="slide">
   <VideoSection videoId="XEi0Ib-nNGo" title="O minimaliźmie" color={minimalismColor} onBack={() => props.fullpageApi.moveSlideLeft()} />
   </div>
   </>
)