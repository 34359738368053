import React from 'react'
import styled from '@emotion/styled';
import MaterialIcon from '../MaterialIcon'

const micorixColor = [32, 37, 63]
const Header = styled.h2`
    text-align:center;
    color:white;
    margin-top:10vh;
    font-family: 'Roboto Mono';
    cursor: pointer;
    &:hover{
        text-decoration:underline;
    }
    a{
        all:unset;
    }
`
const Logo = styled.img`
    width:25vh;
    margin:auto;
    display: block;
`
const Resources = styled.div`
    display:  flex;
    justify-content: center;
    margin-top:10vh;
    span{
        cursor:pointer;
        display: flex;
        align-items: center;
        color:rgba(255,255,255,.8);
        text-align:center;
    font-family: 'Roboto Mono';
    i{
        color:rgba(255,255,255,.8) !important;
        margin-left:.5em;
    }
    &:hover i{
        transform: translateX(10px);
        }
    }
    
`
const Wrapper = styled.div`
    width:100vw;
    height:100vh;
    background:rgb(${micorixColor.join(',')});
    padding-top:10vh;
   display: flex;
   justify-content: center;
`
const List = styled.ul`
    margin-top:20vh;
    color: white;
    font-family: 'Roboto Mono';
`
export default props => (
   <>
   <div className="slide">
   <Wrapper>
       <div>
       <Logo src="https://avatars0.githubusercontent.com/u/20800459" />
       <Header>
           <a href="https://micorix.com">created by micorix</a>
       </Header>
       <Resources>
           <span onClick={() => props.fullpageApi.moveSlideRight()}>Źródła <MaterialIcon icon="keyboard_arrow_right" /></span>
       </Resources>
       </div>
       </Wrapper>
   </div>
   <div className="slide">
   <Wrapper>
        <List>
            <li>historiasztuki.com.pl</li>
            <li>wikiart.com</li>
            <li>wikipedia.org</li>
        </List>
       </Wrapper>
   </div>
   </>
)