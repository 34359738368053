import React from 'react'
import styled from '@emotion/styled';
import WikiTooltip from '../WikiTooltip';
import MaterialIcon from '../MaterialIcon'
import Masonry from 'react-masonry-component'

import {
    Container,
    HeaderImage,
    Content,
    Heading,
    Desc,
    Nav,
    NavItem,
    Biogram,
    ArtistImage,
    WikiIcon,
    VideoSection,
    NavItemLink
} from '../StylePage'
const popartColor = [255, 177, 66]
export default props => (
   <>
   <div className="slide">
   <Container columns="1fr 3fr">
        <HeaderImage color={popartColor}>
            <img  src="https://www.historiasztuki.com.pl/ilustracje/RZEZBA/POP-Lichtenstein-La_Cara_de_Barcelona.jpg" />
        </HeaderImage>
        <Content>
        <Heading color={popartColor}>Popart</Heading>
        <Desc>
        Pop-art to sztuka bez złudzeń. Uważa się, że był reakcją z jednej strony na oschły, abstrakcjonistyczny <WikiTooltip query="Modernizm (sztuka)">modernizm</WikiTooltip>, z drugiej zaś na starannie zaprogramowaną spontaniczność <WikiTooltip query="Informel">informelu</WikiTooltip>. Nie był ani jednym, ani drugim. Był metodą produkcji obiektów przeznaczonych do sprzedania jako dzieła sztuki. Metodę tę przyjęli artyści, którzy doskonale znali mechanizmy rynku i wiedzieli o tym, że dowcipne ready-mades dadaistów przestały być śmieszne. Dla publiczności, która zakosztowała pierwszych uroków dobrobytu istniało jedno bóstwo - puszka z pomidorową zupą Campbella.
        </Desc>
        <Nav>
           
            <NavItem color={popartColor} onClick={() => props.fullpageApi.moveTo(3, 1)}>
                <div>
                    <MaterialIcon icon="person" />
                </div>
                <span>Marisol Escobar</span>
            </NavItem>
            <NavItem color={popartColor} onClick={() => props.fullpageApi.moveTo(3, 3)}>
                <div>
                <MaterialIcon icon="movie" />
                </div>
                <span>Historia pop-artu</span>
            </NavItem>
            <NavItem color={popartColor} onClick={() => props.fullpageApi.moveTo(3, 2)}>
                <div>
                    <MaterialIcon icon="person" />
                </div>
                <span>Andy Warhole</span>
            </NavItem>
            <NavItemLink color={popartColor} href="https://pl.wikipedia.org/wiki/Pop art" target="_blank" rel="noopener norefferer">
          
                <div>
                    <WikiIcon />
                </div>
                <span>Wikipedia</span>
              
            </NavItemLink>
            
          
        </Nav>
        </Content>
    </Container>
   </div>
   <div className="slide">
   <Container columns="1fr 3fr">
        
        <div>
        <Biogram>
            <img src="https://upload.wikimedia.org/wikipedia/commons/thumb/0/04/Marisol_Escobar_NYWTS.jpg/800px-Marisol_Escobar_NYWTS.jpg"/>
            <div>
            <h3>Marisol Escobar</h3>
            <h5>1930-2016</h5>
            </div>
        </Biogram>
        </div>
        <Masonry>
    
     <ArtistImage width="30%">
            <img src="https://uploads6.wikiart.org/images/roy-lichtenstein/explosion-i-1965(1).jpg" />
            <div>
                <h5>Explosion</h5>
                <span>1965</span>
            </div>
        </ArtistImage>
     
     <ArtistImage width="30%">
            <img src="https://uploads1.wikiart.org/images/roy-lichtenstein/the-head-1992.jpg!Large.jpg" />
            <div>
                <h5>The Head</h5>
                <span>1991-1992</span>
            </div>
        </ArtistImage>
    
     <ArtistImage width="30%">
            <img src="https://uploads3.wikiart.org/images/roy-lichtenstein/glass-iv-1977(1).jpg" />
            <div>
                <h5>Glass IV</h5>
                <span>1997</span>
            </div>
        </ArtistImage>
     
            </Masonry>
    </Container>
   </div>
   <div className="slide">
   <Container columns="1fr 3fr">
        
        <div>
        <Biogram>
            <img src="https://uploads3.wikiart.org/images/andy-warhol.jpg!Portrait.jpg"/>
            <div>
            <h3>Andy Warhol</h3>
            <h5>1928-1987</h5>
            </div>
        </Biogram>
        </div>
        <Masonry>
    
     <ArtistImage width="30%">
            <img src="https://uploads1.wikiart.org/images/andy-warhol/brillo-soap-pads-boxes-1964.jpg!Large.jpg" />
            <div>
                <h5>Brillo Soap Pads Boxes</h5>
                <span>1964</span>
            </div>
        </ArtistImage>
     
     <ArtistImage width="50%">
            <img src="https://fimgs.net/himg/o.58157.jpg" />
            <div>
                <h5>You're In</h5>
                <span>1967</span>
            </div>
        </ArtistImage>
            </Masonry>
    </Container>
   </div>
   <div className="slide">
   <VideoSection videoId="6qr7cdpGDRo" title="Historia pop-artu" color={popartColor} onBack={() => props.fullpageApi.moveSlideLeft()} />
   </div>
   </>
)