import React from 'react'
import styled from '@emotion/styled';
import WikiTooltip from '../WikiTooltip';
import MaterialIcon from '../MaterialIcon'
import Masonry from 'react-masonry-component'

import {
    Container,
    HeaderImage,
    Content,
    Heading,
    Desc,
    Nav,
    NavItem,
    Biogram,
    ArtistImage,
    WikiIcon,
    VideoSection,
    NavItemLink,
    HeaderImageWrapper
} from '../StylePage'
const photorealismColor = [170, 166, 157]
export default props => (
   <>
   <div className="slide">
   <Container columns="1fr 3fr">
        <HeaderImageWrapper>
        <HeaderImage color={photorealismColor}>
            <img  src="https://www.historiasztuki.com.pl/ilustracje/RZEZBA/CON-Hanson_CheerleaderwithSurfer.jpg" />
        </HeaderImage>
        </HeaderImageWrapper>
        <Content>
        <Heading color={photorealismColor}>Fotorealizm</Heading>
        <Desc>
        Fotorealizm jest spokrewniony z pop-artem, powstał w połowie lat 60-tych XX wieku, 
        a na początku lat 70-tych stał się samodzielną metodą twórczą. 
        Polega na przedstawianiu rzeczywistości metodami malarskimi lub rzeźbiarskimi z dokładnością 
        odpowiadającą wysokorozdzielczej fotografii. Programowa, absolutna zgodność z rzeczywistością 
        i stosowanie fotografii jako „matrycy” dzieła tworzy specyficzny dystans między twórcą a tematem
         nadając dziełu cechy reporterskiej obiektywności. Fotorealizm jest odwrotnością <WikiTooltip query="Impresjonizm">impresjonizmu</WikiTooltip>,
          chociaż z impresjonizmem łączy go programowa akceptacja rzeczywistości i akcentowanie jej urody.
        </Desc>
        <Nav>
           
            <NavItem color={photorealismColor} onClick={() => props.fullpageApi.moveTo(5, 1)}>
                <div>
                    <MaterialIcon icon="person" />
                </div>
                <span>Duane Hanson</span>
            </NavItem>
            <NavItem color={photorealismColor} onClick={() => props.fullpageApi.moveTo(5, 2)}>
                <div>
                <MaterialIcon icon="movie" />
                </div>
                <span>O fotorealiźmie</span>
            </NavItem>
            <NavItemLink color={photorealismColor} href="https://pl.wikipedia.org/wiki/Hiperrealizm" target="_blank" rel="noopener norefferer">
          
                <div>
                    <WikiIcon />
                </div>
                <span>Wikipedia</span>
              
            </NavItemLink>
            
          
        </Nav>
        </Content>
    </Container>
   </div>
   <div className="slide">
   <Container columns="1fr 3fr">
        
        <div>
        <Biogram>
            <img src="https://upload.wikimedia.org/wikipedia/commons/f/f9/Duane-Hanson.jpg"/>
            <div>
            <h3>Duane Hanson</h3>
            <h5>1925-1996</h5>
            </div>
        </Biogram>
        </div>
        <Masonry>
    
     <ArtistImage width="30%">
            <img src="https://www.historiasztuki.com.pl/ilustracje/RZEZBA/CON-Hanson_CheerleaderwithSurfer.jpg" />
            <div>
                <h5>Cheerleader/Surfer</h5>
                <span>1988/1987</span>
            </div>
        </ArtistImage>
     
     <ArtistImage width="30%">
            <img src="https://www.historiasztuki.com.pl/ilustracje/RZEZBA/CON-Hanson_ManonMower.jpg" />
            <div>
                <h5>Man on Mower</h5>
                <span>1995</span>
            </div>
        </ArtistImage>
    
     <ArtistImage width="20%">
            <img src="https://upload.wikimedia.org/wikipedia/en/e/ec/Duane-Hanson-Sculpture-Woman-Eating-Synthetic-Material-1971.jpg" />
            <div>
                <h5>Woman Eating</h5>
                <span>1971</span>
            </div>
        </ArtistImage>
        <ArtistImage width="20%">
            <img src="https://upload.wikimedia.org/wikipedia/commons/3/3b/Man_on_bench_-_duane_hanson.jpg" />
            <div>
                <h5>Man on bench</h5>
                <span>1977</span>
            </div>
        </ArtistImage>
        
            </Masonry>
    </Container>
   </div>
   <div className="slide">
   <VideoSection videoId="bMa2r4d7EgE" title="O fotorealiźmie" color={photorealismColor} onBack={() => props.fullpageApi.moveSlideLeft()} />
   </div>
   </>
)