import React from 'react'
import styled from '@emotion/styled'
import MaterialIcon from './MaterialIcon'
import DotDotDot from 'react-dotdotdot'
const Tooltip = styled.span`
    display: inline-block;
    position: relative;
    
    .trigger{
        cursor:pointer;
        position: relative;
        display: inline-block;
        transition: color .2s .1s;
        padding: 0 .2em;
        &::after{
            position: absolute;
            content: '';
            bottom: 0px;
            left:0;
            width:100%;
            height:2px;
            background: black;
            z-index:-1;
            transition: .2s all;
        }
    }
    
    .box{
        display: inline-block;
        position: absolute;
        visibility: hidden;
        top:0;
        left:0;
        width:20vw;
        transform: translate(0,calc(-100%));
        
        font-size:.8em;
        opacity:0;
        background:black;
        transition: visibility .2s, opacity .2s;

        .header{
            display: none;
            background:white;
            border:2px solid black;
            color:black;
            padding: 0 10px;
            display: flex;
            align-items: center;
            justify-content: space-between;
            span, i{
                color:black !important;
            }
            i{
                cursor:pointer;
               
            }
        }
        a{
            all:unset;
            display: flex;
            align-items: center;
        }
        .desc{
            padding:10px;
            color:white;
            div:first-of-type{
                span{
                    div:first-of-type{
                        display: none;
                    }
                }
            }
        }
    }
    &:hover{
        span{
            color:white;
            &::after{
                height:calc(100% + 12px);
            }
        }
        .box{
            visibility: visible;
            opacity:1;
            
            .desc{
        display: block;
            }
        }
    }
`

export default class extends React.Component{
    constructor(props){
        super(props)
        this.state = {
            info: null
        }
        if(typeof window !== 'undefined'){
            this.fetchInfo()
        }
    }
    fetchInfo = () => {
        return new Promise((resolve, reject) => {
        fetch(`https://pl.wikipedia.org/w/api.php?format=json&action=query&&origin=*&prop=extracts&exintro&explaintext&redirects=1&titles=${this.props.query}`)
        .then(res => res.json())
        .then(res => {
            if(!res.query || !res.query.pages || res.query.pages.length === 0){
                reject()
                console.log(res)
            }
            console.log(res)
            let page = Object.values(res.query.pages)[0]
            let info = {
                title: res.title,
                desc: page.extract
            }
            this.setState({info}, () => resolve(info))
        })
        })
    }
    render(){
        console.log(this.state)
        return (<Tooltip>
            <span className="trigger">{this.props.children}</span>
            <div className="box">
            <div className="header">
            <span>Wikipedia</span>
            <a href={`https://pl.wikipedia.org/wiki/${this.props.query}`}
            target="_blank"
            rel="noopener norefferer" >
            <MaterialIcon icon="link" />
            </a>
            </div>
            <div className="desc">
            <DotDotDot clamp={6}>
            {this.state.info && this.state.info.desc}
      </DotDotDot>
            
            </div>
            </div>
        </Tooltip>)
    }
}