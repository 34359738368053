import React from 'react'
import SEO from "../seo"
import styled from '@emotion/styled'
import MaterialIcon  from '../MaterialIcon';
import { keyframes } from '@emotion/core';
const TimelineWrapper = styled.div`
    position:fixed;
    width:80vw;
    left:10vw;
    top:180%;
`
const Timeline = styled.div`
  width:100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
 
`
const Decade = styled.span`
height:20px;
    width:4px;
    background:black;
    position: relative;
    cursor: pointer;
    &::after{
      content: '${props => props.decade}';
      position: absolute;
      bottom: calc(100% + 2em);
      left:0;
      transform: translate(-50%, 0);
      text-align: center;
      font-family:Roboto;
      
    }
`
const Year = styled.span`
height:10px;
    width:2px;
    background:rgba(0,0,0,0.8);
    position: relative;
    cursor: pointer;
    &::after{
      content: '${props => props.year}';
      position: absolute;
      top: calc(100% + 1em);
      left:0;
      transform: translate(-50%, 0);
      text-align: center;
      font-family:Roboto;
      font-size:0.8em;
      
      opacity:0;
      transition:.2s all;
    }
    &:hover::after{
      opacity:1;
    }
    
`
const Period = styled.div`
    position: absolute;
    top:0;
    left:${props => props.yearStart-1900}%;
    height:100%;
    width:${props => props.active ? (props.yearEnd-1900)-(props.yearStart-1900) : '0'}%;
    background:rgb(${props => props.color.join(',')});
    z-index:${props => props.zIndex};
    transition: .5s all;
`
const Container = styled.div`
    width:80%;
    margin:auto;
`
const Flex = styled.div`
    width:100%;
    display: flex;
    justify-content: ${props => props.center ? 'center' : 'space-between'};
    margin-bottom:10vh;
`
const ElementWrapper = styled.div`
    margin: 0 2em;
`
const StyleWrapper = styled.div`
    cursor:pointer;
    h4{
        margin:0;
        transition: .5s all;
    }
    &:hover h4{
        transform: scale(1.3);
        transform-origin: 0 0;
    }
`
const ImageWrapper = styled.div` 
    position: relative;
    max-height:40vh;
    margin-bottom:.7em;
    img{
        max-height:40vh;
        margin:0;
    }
 
   .border-top{
       display: block;
       width:0;
       height:${props => props.borderWidth}px;
       background: rgb(${props => props.borderColor.join(',')});
       position: absolute;
       top:-${props => props.borderWidth}px;
       left:-${props => props.borderWidth}px;
       transition: width ${props => props.duration}s;
   }
   .border-bottom{
        display: block;
        width:0;
        height:${props => props.borderWidth}px;
        background: rgb(${props => props.borderColor.join(',')});
        position: absolute;
        bottom:-${props => props.borderWidth}px;
        right:-${props => props.borderWidth}px;
        transition: width ${props => props.duration}s;
    }
   .border-left{
        display: block;
        height:0;
        width:${props => props.borderWidth}px;
        background: rgb(${props => props.borderColor.join(',')});
        position: absolute;
        left:-${props => props.borderWidth}px;
        bottom:0;
        transition: height ${props => props.duration}s;
    }
    .border-right{
        display: block;
        height:0;
        width:${props => props.borderWidth}px;
        background: rgb(${props => props.borderColor.join(',')});
        position: absolute;
        right:-${props => props.borderWidth}px;
        top:0;
        transition: height ${props => props.duration}s;
        }
    &:hover{
        .border-top{
            width:calc(100% + ${props => props.borderWidth*2}px);
        }
        .border-bottom{
            width:calc(100% + ${props => props.borderWidth*2}px);
        }
        .border-left, .border-right{
            height:100%;
        }
    }
`
const SlidesWrapper = styled.div`
    .fp-controlArrow{
        display: none;
    }
    .control{
        position: fixed;
        z-index:100;
        top:150%;
        transform: translateY(-50%);
        i{
            font-size:7em !important;
            color:rgba(0,0,0,0.2) !important;
            transition: .2s all;
            cursor:pointer !important;
            &:hover{
                color:rgba(0,0,0,0.5) !important;
            }
        } 
    }
    .control-next{
        right:2em;
    }
    .control-prev{
        left:2em;
    }
`
const styles = {
    popart: {
        image: 'https://www.historiasztuki.com.pl/ilustracje/RZEZBA/POP-Lichtenstein-La_Cara_de_Barcelona.jpg',
        color: [255, 177, 66],
        yearStart: 1923,
        yearEnd: 1997,
        title: 'Pop art',
        section: 3
    },
    minimalism: {
        image: 'https://www.historiasztuki.com.pl/ilustracje/RZEZBA/CON-Judd-71.jpg',
        color: [255, 121, 63],
        yearStart: 1912,
        yearEnd:2000,
        title: 'Minimalizm',
        section: 4
    },
    photorealism: {
        image: 'https://www.historiasztuki.com.pl/ilustracje/RZEZBA/CON-Hanson_CheerleaderwithSurfer.jpg',
        color: [170, 166, 157],
        yearStart: 1960,
        yearEnd:2000,
        title: 'Fotorealizm',
        section: 5
    },
    surrealism: {
        image: 'https://www.historiasztuki.com.pl/ilustracje/RZEZBA/Dali-Nobility-of-Time.jpg',
        color: [33, 140, 116],
        yearStart: 1920,
        yearEnd:1940,
        title: 'Surrealizm',
        section: 6
    },
    socrealism: {
        image: 'https://www.historiasztuki.com.pl/ilustracje/RZEZBA/SOC-Charkow.jpg',
        color: [204, 174, 98],
        yearStart: 1900,
        yearEnd:1991,
        title: 'Socrealizm',
        section: 7
    },
    constructivism: {
        image: 'https://www.historiasztuki.com.pl/ilustracje/RZEZBA/MOD-Gabo-Glowa.jpg',
        color: [132, 129, 122],
        yearStart: 1900,
        yearEnd:2000,
        title: 'Konstruktywizm',
        section: 8
    },
    dada: {
        image: 'https://upload.wikimedia.org/wikipedia/commons/thumb/c/ce/Marcel_Duchamp.jpg/800px-Marcel_Duchamp.jpg',
        color: [64, 64, 122],
        yearStart: 1915,
        yearEnd:1925,
        title: 'Dadaizm',
        section: 9
    },
    cubism: {
        image: 'https://www.historiasztuki.com.pl/ilustracje/RZEZBA/CON-Picasso-11.jpg',
        color: [255, 218, 121],
        yearStart: 1907,
        yearEnd:1930,
        title: 'Kubizm',
        section: 10
    }
    
    
}
const Style = props => (
    <StyleWrapper {...props.style}>
                    <ImageWrapper {...props}>
                    <span className="border-top" />
                    <span className="border-left" />
                    <span className="border-bottom" />
                    <span className="border-right" />
                    <img src={props.image} />
                    </ImageWrapper>
                        <h4>{props.title}</h4>
                    </StyleWrapper>
)
export default class extends React.Component{
    constructor(props){
        super(props)
        this.state = {
            currentStyle: null
        }
    }
    chooseStyle = (style) => {
        return {
            onMouseOver: e => this.setState({currentStyle: style}),
            onMouseOut: e => this.setState({currentStyle: null})
        }
    }
    render(){
        return (
            <>
            <TimelineWrapper>
            <Timeline>
            {
                    Object.entries(styles).map(([key, value], i) => (
                        <Period 
                        yearStart={value.yearStart}
                        yearEnd={value.yearEnd}
                        color={value.color}
                        zIndex={-i-1}
                        active={this.state.currentStyle === key} />
                    ))
                }
                   
                      {(() => {
                        let array = []
                        for (let i = 0; i < 10; i++) {
                          array.push(<Decade decade={`19${i}0`}/>)
                          for (let j = 1; j <= 9; j++) {
                            array.push(<Year year={`19${i}${j}`} />)
                          }
                        }
                        array.push(<Decade decade="2000"/>)
                        return array
                      })()}
                    </Timeline>
                    </TimelineWrapper>
                    <SlidesWrapper>
                        <div class="control control-prev">
                            <MaterialIcon
                            icon="keyboard_arrow_left"
                            onClick={() => this.props.fullpageApi.moveSlideLeft()}
                            />
                        </div>
                        <div class="control control-next">
                        <MaterialIcon
                            icon="keyboard_arrow_right"
                            onClick={() => this.props.fullpageApi.moveSlideRight()}
                            />
                        </div>
            <div className="slide">
            <Container>
            <Flex>
            <ElementWrapper>
                <Style onClick={() => this.props.fullpageApi.moveTo(styles.popart.section)}
                    duration={0.5}
                    borderColor={styles.popart.color}
                    borderWidth={4}
                    style={this.chooseStyle('popart')}
                    image={styles.popart.image}
                    title={styles.popart.title}
                    />
            </ElementWrapper>
            <ElementWrapper>
                <Style onClick={() => this.props.fullpageApi.moveTo(styles.minimalism.section)}
                    duration={0.5}
                    borderColor={styles.minimalism.color}
                    borderWidth={4}
                    style={this.chooseStyle('minimalism')}
                    image={styles.minimalism.image}
                    title={styles.minimalism.title}
                    />
            </ElementWrapper>    
            <ElementWrapper>
                <Style onClick={() => this.props.fullpageApi.moveTo(styles.photorealism.section)}
                    duration={0.5}
                    borderColor={styles.photorealism.color}
                    borderWidth={4}
                    style={this.chooseStyle('photorealism')}
                    image={styles.photorealism.image}
                    title={styles.photorealism.title}
                    />
            </ElementWrapper>          
            </Flex>
            <div>
            <br />
            <br />
            </div>
                    </Container>
                    </div>
            <div className="slide" >
            <Container>
            <Flex>
            <ElementWrapper>
                <Style onClick={() => this.props.fullpageApi.moveTo(styles.surrealism.section)}
                    duration={0.5}
                    borderColor={styles.surrealism.color}
                    borderWidth={4}
                    style={this.chooseStyle('surrealism')}
                    image={styles.surrealism.image}
                    title={styles.surrealism.title}
                    />
            </ElementWrapper>    
            <ElementWrapper>
                <Style onClick={() => this.props.fullpageApi.moveTo(styles.socrealism.section)}
                    duration={0.5}
                    borderColor={styles.socrealism.color}
                    borderWidth={4}
                    style={this.chooseStyle('socrealism')}
                    image={styles.socrealism.image}
                    title={styles.socrealism.title}
                    />
            </ElementWrapper>   
            <ElementWrapper>
                <Style onClick={() => this.props.fullpageApi.moveTo(styles.constructivism.section)}
                    duration={0.5}
                    borderColor={styles.constructivism.color}
                    borderWidth={4}
                    style={this.chooseStyle('constructivism')}
                    image={styles.constructivism.image}
                    title={styles.constructivism.title}
                    />
            </ElementWrapper>          
            </Flex>
            <div>
            <br />
            <br />
            </div>
                    </Container>
            </div>
            <div className="slide" >
            <Container>
            <Flex center>
            <ElementWrapper>
                <Style onClick={() => this.props.fullpageApi.moveTo(styles.dada.section)}
                    duration={0.5}
                    borderColor={styles.dada.color}
                    borderWidth={4}
                    style={this.chooseStyle('dada')}
                    image={styles.dada.image}
                    title={styles.dada.title}
                    />
            </ElementWrapper>    
                    
            </Flex>
            <div>
            <br />
            <br />
            </div>
                    </Container>
            </div>
            </SlidesWrapper>
            </>
        )
    }
} 