import React from 'react'
import styled from '@emotion/styled';
import WikiTooltip from '../WikiTooltip';
import MaterialIcon from '../MaterialIcon'
import Masonry from 'react-masonry-component'

import {
    Container,
    HeaderImage,
    Content,
    Heading,
    Desc,
    Nav,
    NavItem,
    Biogram,
    ArtistImage,
    WikiIcon,
    VideoSection,
    NavItemLink,
    HeaderImageWrapper
} from '../StylePage'
const dadaColor = [64, 64, 122]
export default props => (
   <>
   <div className="slide">
   <Container columns="1fr 3fr">
        <HeaderImageWrapper>
        <HeaderImage color={dadaColor}>
            <img  src="https://upload.wikimedia.org/wikipedia/commons/thumb/c/ce/Marcel_Duchamp.jpg/800px-Marcel_Duchamp.jpg" />
        </HeaderImage>
        </HeaderImageWrapper>
        <Content>
        <Heading color={dadaColor}>Dadaizm</Heading>
        <Desc>
        Dadaizm cechuje totalna negacja tradycji w sztuce oraz zainteresowanie ruchem.
        Pierwszym dziełem trójwymiarowym przedstawiającym ruch było Koło rowerowe z 
        1913 r. <WikiTooltip query="Marcel Duchamp">Marcela Duchampa</WikiTooltip> – 
        dzieło dość naiwne, bo przedstawiające – zgodnie z nazwą - raczej koło rowerowe,
         niż jakikolwiek ruch. Jednak znaczenie Duchampa w historii sztuki wynika z czego innego.
         <br />
Jego kolejne dzieła, tzw. <WikiTooltip query="ready-made">ready-mades</WikiTooltip>, Suszarka z 1914 r.
 i (szczególnie) Fontanna z 1917 r. 
stanowią dla jednych kamienie milowe postępu, dla innych świadectwo definitywnego końca wielkiej sztuki.
<br />
Niezależnie od intencji, Duchamp stworzył nimi kilka precedensów.
Pierwszy to wyniesienie do rangi dzieła przedmiotu pospolitego, a więc desakralizacja sztuki.
 Desakralizacja niemal kompletna, ponieważ dziełem sztuki ogłoszono obiekt służący zaspokajaniu
  potrzeb fizjologicznych.
Drugi do nadanie rangi dzieła przedmiotowi produkowanemu seryjnie, powtarzalnemu i wymienialnemu
 i – co najważniejsze – nie wymagającemu ani talentu ani warsztatu.
Trzeci precedens to stworzenie dzieła przez mianowanie.
        </Desc>
        <Nav>
           
            <NavItem color={dadaColor} onClick={() => props.fullpageApi.moveTo(9, 1)}>
                <div>
                    <MaterialIcon icon="person" />
                </div>
                <span>Marcel Duchamp</span>
            </NavItem>
            <NavItem color={dadaColor} onClick={() => props.fullpageApi.moveTo(9, 2)}>
                <div>
                <MaterialIcon icon="movie" />
                </div>
                <span>Art or prank?</span>
            </NavItem>
            <NavItemLink color={dadaColor} href="https://pl.wikipedia.org/wiki/Dadaizm" target="_blank" rel="noopener norefferer">
          
                <div>
                    <WikiIcon />
                </div>
                <span>Wikipedia</span>
              
            </NavItemLink>
            
          
        </Nav>
        </Content>
    </Container>
   </div>
   <div className="slide">
   <Container columns="1fr 3fr">
        
        <div>
        <Biogram>
            <img src="https://uploads6.wikiart.org/images/marcel-duchamp.jpg!Portrait.jpg"/>
            <div>
            <h3>Marcel Duchamp</h3>
            <h5>1887-1968</h5>
            </div>
        </Biogram>
        </div>
        <Masonry>
    
     <ArtistImage width="20%">
            <img src="https://uploads4.wikiart.org/images/marcel-duchamp/bicycle-wheel-1913.jpg!Large.jpg" />
            <div>
                <h5>Bicycle Wheel</h5>
                <span>1913</span>
            </div>
        </ArtistImage>
     
     <ArtistImage width="20%">
            <img src="https://uploads1.wikiart.org/images/marcel-duchamp/bottlerack-1914.jpg!Large.jpg" />
            <div>
                <h5>Bottlerack</h5>
                <span>1914</span>
            </div>
        </ArtistImage>
    
     <ArtistImage width="20%">
            <img src="https://uploads6.wikiart.org/00177/images/marcel-duchamp/fountain.jpg!Large.jpg" />
            <div>
                <h5>Fountain</h5>
                <span>1917</span>
            </div>
        </ArtistImage>
        <ArtistImage width="20%">
            <img src="https://uploads1.wikiart.org/images/marcel-duchamp/in-advance-of-the-broken-arm-1915.jpg!Large.jpg" />
            <div>
                <h5>In Advance of the Broken Arm</h5>
                <span>1915</span>
            </div>
        </ArtistImage>
        <ArtistImage width="20%">
            <img src="https://uploads7.wikiart.org/images/marcel-duchamp/why-not-sneeze-rose-s%C3%A9lavy-1921.jpg" />
            <div>
                <h5>Why not Sneeze, Rose Sélavy</h5>
                <span>1921</span>
            </div>
        </ArtistImage>
        <ArtistImage width="20%">
            <img src="https://uploads8.wikiart.org/images/marcel-duchamp/with-hidden-noise-1916.jpg!Large.jpg" />
            <div>
                <h5>With Hidden Noise</h5>
                <span>1916</span>
            </div>
        </ArtistImage>
        <ArtistImage width="20%">
            <img src="https://uploads7.wikiart.org/images/marcel-duchamp/rotary-demisphere-1924.jpg" />
            <div>
                <h5>Rotary demisphere</h5>
                <span>1924</span>
            </div>
        </ArtistImage>
            </Masonry>
    </Container>
   </div>

   <div className="slide">
   <VideoSection videoId="2ZlrHyzIwcI" title="Art or prank?" color={dadaColor} onBack={() => props.fullpageApi.moveSlideLeft()} />
   </div>
   </>
)