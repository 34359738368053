import React from "react"
import styled from '@emotion/styled'
import MaterialIcon  from './MaterialIcon';

const Navbar = styled.div`
  position:fixed;
  top:0;
  left:0;
  width:100%;
  z-index:999999;
`
const InnerWrapper = styled.nav`
    padding: 2em 0;
    width:80%;
    margin:auto;
    display: flex;
    align-items: center;
    justify-content: space-between;
`
const Brand = styled.h3`
  margin:0 2em 0 0;
`
const MenuIcon = styled.div`

  i{
    cursor:pointer !important;
    font-size:30px !important;
  color:black !important;
  }
`
const Menu = styled.div`
  display: flex;
  align-items: center;
  background:white;
  padding:10px;
  border-radius:5px;
`
const MicorixBrand = styled.a`
all:unset;
cursor:pointer;
  display:flex;
  align-items: center;
  justify-content: center;
  font-family:Roboto Mono;
  font-size:.8em;
  background:white;
  padding:5px;
  border-radius:5px;
  img{
    width:4em;
    margin:0 0 0 1em;
    border-radius:5px;
  }
  &:hover{
    text-decoration:underline;
  }
`
export default props => (
    <Navbar>
        <InnerWrapper>
          <Menu>

          <Brand>Rzeźba XX w.</Brand>
          <MenuIcon onClick={() => props.fullpageApi.moveTo(2)}>
          <MaterialIcon icon="menu" />
          </MenuIcon>
       
          </Menu>
          
          <MicorixBrand href="https://micorix.com">
            <span>created by micorix</span>
            <img src="https://avatars0.githubusercontent.com/u/20800459" />
          </MicorixBrand>
          </InnerWrapper>
        </Navbar>
)