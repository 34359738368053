import React from 'react'
import SEO from "../seo"
import styled from '@emotion/styled'
import MaterialIcon  from '../MaterialIcon';
import { keyframes } from "@emotion/core";
const friction = 1/4
const SiteWrapper = styled.div`
  display: grid;
  grid-template-columns:1fr 1fr;
  height:100%;
  h1{
    font-weight:900;
    font-size:10em;
  }
  div{
    display: flex;
    align-items: center;
    justify-content: center;
  }
`
const Left = styled.div`
position: relative;
`
const DarkenText = styled.h1`
  position: relative;
  color:rgba(0,0,0,0.5);
  &::before{
    content:'${props => props.text}';
    position: absolute;
    top:${props => props.shadowY}px;
    left:${props => props.shadowX}px;
    transition: .05s all;
    z-index:-1;
    color:rgba(0,0,0,0.5);
  }
  &::after{
    content:'${props => props.text}';
    position: absolute;
    top:0;
    left:0;
    z-index:-1;
    background: url('http://hyperallergic.com/wp-content/uploads/2016/05/DSC_0183.jpg');
    background-position:center center;
    background-size:cover;
    -webkit-background-clip: text;
  }
`
const Right = styled.div`
background: rgba(0,0,0,0.8);
color:white;
position: relative;
&::after{
  content:'';
  width:100%;
  height:100%;
  position: absolute;
  top:0;
  left:0;
  
  background: url('http://hyperallergic.com/wp-content/uploads/2016/05/DSC_0183.jpg');
background-position:center center;
background-size:cover;
  z-index:-1;
}
h1{
  transition: .05s all;
  text-shadow: ${props => props.shadowX}px ${props => props.shadowY}px 0px rgba(255,255,255,0.5);
}
`

const topBottomAnimation = keyframes`
  0%{
    top:70%;
  }
  40%{
    top:72%;
  }
  80%{
    top:70%;
  }
`
const MoreIcon = styled.div`
  width:100%;
  text-align: center;
  top:70%;
  left:0;
  position: absolute;
  animation: ${topBottomAnimation} 3s ease-out infinite;
  cursor:pointer;
  i{
    color:black !important;
    font-size:5em !important;
  }
`
 export default class extends React.Component{
  constructor(props){
    super(props)
    this.state = {
      y: 0,
      x: 0,
      followY: 0,
      followX: 0
    }
  }
  componentDidMount(){
    document.addEventListener('mousemove', this.handleMouseMove)
  }
  componentWillUnmount(){
    document.removeEventListener('mousemove', this.handleMouseMove)
  }
  handleMouseMove = (e) => {
    console.log(e)
    let mouseX = Math.max(-100, Math.min(100, window.innerWidth / 2 - e.clientX))
    let mouseY = Math.max(-100, Math.min(100, window.innerHeight / 2 - e.clientY))
    let followX = (20 * mouseX) / 100
    let followY = (10 * mouseY) / 100
    this.setState({
      x: followX * friction,
      y: followY * friction
    })
  }
  render(){
    return (
  
    
      <SiteWrapper>
       
        <SEO title="Home" keywords={[`gatsby`, `application`, `react`]} />
        <Left>
          <DarkenText text="Rzeźba" shadowY={-this.state.y} shadowX={-this.state.x}>
          Rzeźba
          
          </DarkenText>
        <MoreIcon onClick={() => this.props.fullpageApi.moveSectionDown()}>
          <MaterialIcon icon="keyboard_arrow_down" />
        </MoreIcon>
        </Left>
        <Right shadowY={-this.state.y} shadowX={-this.state.x}>
    <h1>XX w.</h1>
        </Right>
      </SiteWrapper>
   
  )
  }
}