import React, { useState, useEffect } from 'react'
import styled from '@emotion/styled';
import YouTube from 'react-youtube';
import MaterialIcon  from './MaterialIcon';
export const Container = styled.div`
    width:80%;
    margin:auto;
    display: grid;
    grid-template-columns: ${props => props.columns};
    grid-column-gap: 10%;
`
export const HeaderImage = styled.div`
    width:100%;
    position: relative;
    display: block;
    img{
        width:100%;
    }
    &::after{
        content: '';
        position: absolute;
        top:0px;
        left:0px;
        width:100%;
        height:100%;
        background: rgb(${props => props.color.join(',')});
        transform: rotate(10deg);
        z-index:-1;
        transition: .2s all;
        
    }
    &:hover::after{
        top:-.4em;
        left:-.4em;
        height:calc(100% + .8em);
        width:calc(100% + .8em);
    }
`
export const HeaderImageWrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
`
export const Content = styled.div``
export const Heading = styled.h1`
position: relative;
display: inline-block;
transition: color .2s .1s;
padding: 0 .2em;
    &::after{
        position: absolute;
        content: '';
        bottom: -12px;
        left:0;
        width:100%;
        height:4px;
        background: rgb(${props => props.color.join(',')});
        z-index:-1;
        transition: .2s all;
    }
    &:hover{
        color:white;
        &::after{
            height:calc(100% + 12px);
    }
    
`
export const Desc = styled.p`
    font-family:Roboto Mono;
    font-weight:400;
    line-height: 1.7em;
    font-size:1.1em;
`
export const Nav = styled.nav`
    width:60%;
    display: grid;
    grid-template-columns:1fr 1fr;
    grid-column-gap: 1em;
    grid-row-gap: .5em;
`
export const NavItem = styled.div`
    all:unset;
    display: flex;
    cursor:pointer;
    border:4px solid rgb(${props => props.color.join(',')});

    &:hover{
        div{
            i{
                color:inherit !important;
            }
            &::after{
                width:3em;
            }
        }
        span{
            color:white;
            &::after{
                max-width:100%
            }
        }
       
        
    }
    div{
        position: relative;

        &::before{
            content: '';
            position: absolute;
            top: 4px;
            height:calc(100% - 4px);   
            left:0;
            width:100%;
            z-index:-2;
            background:rgb(${props => props.color.join(',')});
        }
        &::after{
            content: '';
            position: absolute;
            top:0;
            right:0;
            width:0;
            height:100%;
            z-index:-1;
            background:white;
            transition: width .2s .2s;
        }
        width:4em;
        height:3em;
       
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top:-4px;
        i{
            color:white !important;
            font-size:1.5em !important;
            transition: color .2s .2s !important;
        }
        
    }
    span{
        display: flex;
        align-items: center;
        justify-content: center;
        font-family:Roboto Mono;
        text-align:center;
        width:100%;
        position: relative;
        transition: color .2s;
        padding:0 10px;
        &::after{
            content: '';
            position: absolute;
            top:0;
            right:0;
            width:100%;
            max-width:0;
            height:100%;
            z-index:-1;
            background:rgb(${props => props.color.join(',')});
            transition: max-width .2s;
        }
    }
`
export const NavItemLink = NavItem.withComponent('a')
export const WikiIcon = styled.i`
    all:unset;
    &::before{
        content:'W';
         
        font-size:1.5em;
        font-family:serif;
    }
`
export const Biogram = styled.div`
    border:3px solid black;
    border-radius:3px;
    img{
        margin-bottom:0;
        width:100%;
    }
    div{
        padding:10px 20px;
    }
    h5{
        margin-bottom:10px;
    }
`
export const ArtistImage = styled.div`
    margin:10px;
    width:${props => props.width};
    transition: transform .2s;
    position: relative;
   
    div{
        background:black;
        color:white;
        padding:10px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        border-radius:3px;
    }
    img{
        margin:0 0 5px 0;
        border-radius:3px;
        width:100%;
    }
    h5{
        margin:0; 
    }
    span{
        font-family:Roboto Mono;
        font-size:.9em;
        
    }
    &:hover{
        transform:scale(1.1);
    }

`
const VideoWrapper = styled.div`
    width:100vw;
    height:100vh;
    position: relative;
    background:black;
    
    h1{
        position: absolute;
        top:20%;
        left:20%;
        padding:20px;
        background: black;
        color:white;
        z-index:5;
        font-size:5em;
        transition: .2s all;
        opacity: ${props => props.overlay ? '1' : '0'};
        visibility: ${props => props.overlay ? 'visible' : 'hidden'};
        small{
            font-size:.5em;
        }
    } 
    div:first-of-type{
        width:100%;
        height:100%;
    }
    &::after{
        content:'';
        position: absolute;
        top:0;
        left:0;
        width:100%;
        height:100%;
        background: rgba(${props => props.color.join(',')}, .8);
        z-index:2;
        transition: .2s all;
        opacity: ${props => props.overlay ? '1' : '0'};
        visibility: ${props => props.overlay ? 'visible' : 'hidden'};
    }
`
const PlayIcon = styled.div`
    position: absolute;
    top:50%;
    left:50%;
    transform:translate(-50%, -50%);
    z-index:5;
    border-radius:50%;
    background:black;
    padding:1em;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor:pointer;
    i{
        color:white !important;
        font-size:5em !important;
    }
`
const BackButton = styled.div`
    position: absolute;
    top:12%;
    left:10%;
    background: black;
    color:white;
    padding:5px;
    border-radius:5px;
    display: flex;
    align-items: center;
    cursor: pointer;
    z-index:5;
    span{
        margin-right:1em;
    }
    i{
        color:white !important;
        font-size:2em !important;
        transition: .2s all !important;
    }
    &:hover{
        i{
            transform:translateX(-5px) !important;
        }
    }
`
export const VideoSection = props => {
    const [state, setState] = useState({
        played:false,
        player:null
    })
    return (
        <VideoWrapper color={props.color} overlay={!state.played}>
        
       <h1>{props.title} <small>(EN)</small></h1>
        <YouTube
  videoId={props.videoId}
  opts={{
      width: '100%',
      height:'100%',
      playerVars:{
          rel: 0
      }
  }}
  onReady={e => setState({...state, player: e.target})}
  onPause={e => {
    setState({...state, played: false})
  }}
/>
{state.player && !state.played && (
    <PlayIcon>
            <MaterialIcon icon="play_arrow" onClick={e => {
                setState({...state, played: true})
                state.player.playVideo()
            }} />
        </PlayIcon>
)}
<BackButton onClick={e => {
    if(state.player){
        setState({...state, played: false})
        state.player.pauseVideo()
    }
    props.onBack && props.onBack(e)
}}>
    <MaterialIcon icon="keyboard_arrow_left" />
    <span>Wróc</span>
</BackButton>
   </VideoWrapper>
    )
}