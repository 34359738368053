import React from 'react'
import styled from '@emotion/styled';
import WikiTooltip from '../WikiTooltip';
import MaterialIcon from '../MaterialIcon'
import Masonry from 'react-masonry-component'

import {
    Container,
    HeaderImage,
    Content,
    Heading,
    Desc,
    Nav,
    NavItem,
    Biogram,
    ArtistImage,
    WikiIcon,
    VideoSection,
    NavItemLink,
    HeaderImageWrapper
} from '../StylePage'
const surrealismColor = [33, 140, 116]
export default props => (
   <>
   <div className="slide">
   <Container columns="1fr 3fr">
        <HeaderImageWrapper>
        <HeaderImage color={surrealismColor}>
            <img  src="https://www.historiasztuki.com.pl/ilustracje/RZEZBA/Dali-Nobility-of-Time.jpg" />
        </HeaderImage>
        </HeaderImageWrapper>
        <Content>
        <Heading color={surrealismColor}>Surrealizm</Heading>
        <Desc>
        Surrealizm jako kierunek artystyczny proklamujący zerwanie z wszelką tradycją, całkowitą wolność twórczą 
         i prymat podświadomości wobec intelektu stanowił ciekawe i inspirujące doświadczenie. 
         W odróżnieniu od dadaizmu negującego istnienie jakichkolwiek reguł, surrealizm uznawał ich istnienie,
          jednak uważał, że nie określa ich rozum lecz tkwiące głęboko w ludzkiej podświadomości popędy. 
          Dlatego uważa się go za artystyczną interpretację <WikiTooltip query="Sigmund_Freud">freudyzmu</WikiTooltip>. Również w odróżnieniu od dadaizmu 
          surrealizm nie kwestionował znaczenia warsztatu, a wielu surrealistów było wybitnymi malarzami 
          (w tradycyjnym znaczeniu).
        </Desc>
        <Nav>
           
            <NavItem color={surrealismColor} onClick={() => props.fullpageApi.moveTo(6, 1)}>
                <div>
                    <MaterialIcon icon="person" />
                </div>
                <span>Joan Miro</span>
            </NavItem>
            <NavItem color={surrealismColor} onClick={() => props.fullpageApi.moveTo(6, 3)}>
                <div>
                <MaterialIcon icon="movie" />
                </div>
                <span>Surrealizm - Wielkie idee</span>
            </NavItem>
            <NavItem color={surrealismColor} onClick={() => props.fullpageApi.moveTo(6, 2)}>
                <div>
                    <MaterialIcon icon="person" />
                </div>
                <span>Salvador Dali</span>
            </NavItem>
            <NavItemLink color={surrealismColor} href="https://pl.wikipedia.org/wiki/Surrealizm" target="_blank" rel="noopener norefferer">
          
                <div>
                    <WikiIcon />
                </div>
                <span>Wikipedia</span>
              
            </NavItemLink>
            
          
        </Nav>
        </Content>
    </Container>
   </div>
   <div className="slide">
   <Container columns="1fr 3fr">
        
        <div>
        <Biogram>
            <img src="https://uploads4.wikiart.org/images/joan-miro(1).jpg!Portrait.jpg"/>
            <div>
            <h3>Joan Miro</h3>
            <h5>1893-1983</h5>
            </div>
        </Biogram>
        </div>
        <Masonry>
    
     <ArtistImage width="20%">
            <img src="https://uploads8.wikiart.org/00184/images/joan-miro/dona-i-ocell-1.jpg!Large.jpg" />
            <div>
                <h5>Woman and Bird</h5>
                <span>1983</span>
            </div>
        </ArtistImage>
     
     <ArtistImage width="20%">
            <img src="https://cdn.theculturetrip.com/wp-content/uploads/2017/02/1024px-joan_mir-_ocell_lunar_mncars_2.jpg" />
            <div>
                <h5>Lunar Bird</h5>
                <span>1966</span>
            </div>
        </ArtistImage>
    
     <ArtistImage width="20%">
            <img src="https://img.theculturetrip.com/768x/wp-content/uploads/2017/02/3857419418_21df3c3e24_b.jpg" />
            <div>
                <h5>The Sun, the Moon and One Star</h5>
                <span>1968</span>
            </div>
        </ArtistImage>
        <ArtistImage width="20%">
            <img src="https://img.theculturetrip.com/768x/wp-content/uploads/2017/02/5015361210_f27aaec9fc_b.jpg" />
            <div>
                <h5>Pair of Lovers Playing with Almond Blossoms</h5>
                <span>1976</span>
            </div>
        </ArtistImage>
        <ArtistImage width="20%">
            <img src="https://img.theculturetrip.com/768x/wp-content/uploads/2017/02/4801789478_c27d783ed2_b.jpg" />
            <div>
                <h5>Solar Bird</h5>
                <span>1966</span>
            </div>
        </ArtistImage>
        <ArtistImage width="20%">
            <img src="https://img.theculturetrip.com/768x/wp-content/uploads/2017/02/845744065_60a4253b33_o.jpg" />
            <div>
                <h5>Personnage</h5>
                <span>1970</span>
            </div>
        </ArtistImage>
        <ArtistImage width="20%">
            <img src="https://img.theculturetrip.com/768x/wp-content/uploads/2017/02/7624334392_95ecfca8f6_k.jpg" />
            <div>
                <h5>Grande Maternité</h5>
                <span>1967</span>
            </div>
        </ArtistImage>
            </Masonry>
    </Container>
   </div>
   <div className="slide">
   <Container columns="1fr 3fr">
        
        <div>
        <Biogram>
            <img src="https://uploads5.wikiart.org/images/salvador-dali.jpg!Portrait.jpg"/>
            <div>
            <h3>Salvador Dali</h3>
            <h5>1904-1989</h5>
            </div>
        </Biogram>
        </div>
        <Masonry>
    
     <ArtistImage width="20%">
            <img src="https://uploads3.wikiart.org/images/salvador-dali/scatalogical-object-functioning-symbolically-the-surrealist-shoe.jpg!PinterestSmall.jpg" />
            <div>
                <h5>Scatalogical Object Functioning Symbolically (The Surrealist Shoe)</h5>
                <span>1931</span>
            </div>
        </ArtistImage>
     
     <ArtistImage width="20%">
            <img src="https://uploads1.wikiart.org/00125/images/salvador-dali/homage-to-duchamp-1.jpg!PinterestSmall.jpg" />
            <div>
                <h5>Homage To Duchamp</h5>
                <span>1973</span>
            </div>
        </ArtistImage>
        <ArtistImage width="20%">
            <img src="https://uploads8.wikiart.org/images/salvador-dali/lobster-telephone-1938-1.jpg!PinterestSmall.jpg" />
            <div>
                <h5>Lobster Telephone</h5>
                <span>1938</span>
            </div>
        </ArtistImage>
        <ArtistImage width="20%">
            <img src="https://uploads1.wikiart.org/images/salvador-dali/moth-and-flame-candelstick.jpg!PinterestSmall.jpg" />
            <div>
                <h5>Moth and Flame. Candelstick</h5>
                <span>1965</span>
            </div>
        </ArtistImage>
        <ArtistImage width="20%">
            <img src="https://uploads6.wikiart.org/images/salvador-dali/lobster-telephone-1938.jpg!PinterestSmall.jpg" />
            <div>
                <h5>Lobster Telephone</h5>
                <span>1938</span>
            </div>
        </ArtistImage>
      
        <ArtistImage width="20%">
            <img src="https://uploads3.wikiart.org/images/salvador-dali/venus-de-milo-with-drawers-1.jpg!PinterestSmall.jpg" />
            <div>
                <h5>Venus de Milo with Drawers</h5>
                <span>1936</span>
            </div>
        </ArtistImage>
      
            </Masonry>
    </Container>
   </div>
   <div className="slide">
   <VideoSection videoId="rp2PRA0qaD4" title="Surrealizm - Wielkie idee" color={surrealismColor} onBack={() => props.fullpageApi.moveSlideLeft()} />
   </div>
   </>
)