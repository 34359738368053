import React, { useState } from "react"

import ReactFullpage from '@fullpage/react-fullpage';
import LandingPage from "../components/pages/LandingPage";
import Navbar from "../components/Navbar";
import Timeline from "../components/pages/Timeline";
import PopArt from "../components/pages/PopArt";
import Minimalism from "../components/pages/Minimalism";
import Photorealism from "../components/pages/Photorealism";
import Surrealism from "../components/pages/Surrealism";
import Socrealism from "../components/pages/Socrealism";
import Constructivism from "../components/pages/Constructivism";
import Dada from "../components/pages/Dada";
import End from "../components/pages/End";


export default props => {
  const [fullpageApiNavbar, setFullpageApi] = useState(null)
  return (
    <>
     <Navbar fullpageApi={fullpageApiNavbar} />
    <ReactFullpage
      render={({ state, fullpageApi }) => {
        setFullpageApi(fullpageApi)
        return (
          <ReactFullpage.Wrapper>
            <div className="section">
              <LandingPage fullpageApi={fullpageApi} />
            </div>
            <div className="section">
              <Timeline fullpageApi={fullpageApi} />
            </div>
            <div className="section">
              <PopArt fullpageApi={fullpageApi} />
            </div>
            <div className="section">
              <Minimalism fullpageApi={fullpageApi} />
            </div>
            <div className="section">
              <Photorealism fullpageApi={fullpageApi} />
            </div>
            <div className="section">
              <Surrealism fullpageApi={fullpageApi} />
            </div>
            <div className="section">
              <Socrealism fullpageApi={fullpageApi} />
            </div>
            <div className="section">
              <Constructivism fullpageApi={fullpageApi} />
            </div>
            <div className="section">
              <Dada fullpageApi={fullpageApi} />
            </div>
            <div className="section">
              <End fullpageApi={fullpageApi} />
            </div>
          </ReactFullpage.Wrapper>
        );
      }}
    />
    </>
  )
}