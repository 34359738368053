import React from 'react'
import styled from '@emotion/styled';
import WikiTooltip from '../WikiTooltip';
import MaterialIcon from '../MaterialIcon'
import Masonry from 'react-masonry-component'

import {
    Container,
    HeaderImage,
    Content,
    Heading,
    Desc,
    Nav,
    NavItem,
    Biogram,
    ArtistImage,
    WikiIcon,
    VideoSection,
    NavItemLink,
    HeaderImageWrapper
} from '../StylePage'
const socrealismColor = [204, 174, 98]
export default props => (
   <>
   <div className="slide">
   <Container columns="1fr 3fr">
        <HeaderImageWrapper>
        <HeaderImage color={socrealismColor}>
            <img  src="https://www.historiasztuki.com.pl/ilustracje/RZEZBA/SOC-Charkow.jpg" />
        </HeaderImage>
        </HeaderImageWrapper>
        <Content>
        <Heading color={socrealismColor}>Socrealizm</Heading>
        <Desc>
        Na początku lat 30-tych XX wieku w komunistycznym <WikiTooltip query="Związek_Socjalistycznych_Republik_Radzieckich">Związku Radzieckim</WikiTooltip> i w narodowosocjalistycznych Niemczech wprowadzono metodami administracyjno-policyjnymi
         nowy styl - socrealizm.
Wspólną cechą wszystkich odmian socrealizmu było nawiązywanie do wielkiej tradycji klasycznej, 
kultywowanie wartości i postaw "państwowotwórczych" i monumentalizacja formy. Głównym jego celem była
 mobilizacja społeczeństwa do intensywnej pracy i przewidywanej walki. 
 <br />
Przy wszystkich regionalnych i ideologicznych odmiennościach socrealizm miał jedną cechę wspólną - nie 
był realizmem, ponieważ nie przedstawiał jakiejkolwiek istniejącej rzeczywistości, ale w ilustrował utopijną wizję społeczeństwa, które dopiero miało powstać - między innymi w wyniku 
 edukacyjnego oddziaływania nowej sztuki.

        </Desc>
        <Nav>
           
            <NavItem color={socrealismColor} onClick={() => props.fullpageApi.moveTo(7, 1)}>
                <div>
                    <MaterialIcon icon="person" />
                </div>
                <span>Wiera Muchina</span>
            </NavItem>
            <NavItemLink color={socrealismColor} href="https://pl.wikipedia.org/wiki/Socrealizm" target="_blank" rel="noopener norefferer">
          
                <div>
                    <WikiIcon />
                </div>
                <span>Wikipedia</span>
              
            </NavItemLink>
            
          
        </Nav>
        </Content>
    </Container>
   </div>
   <div className="slide">
   <Container columns="1fr 3fr">
        
        <div>
        <Biogram>
            <img src="http://www.repozytorium.fn.org.pl/sites/default/files/video/3370/13608_KEYFRAMES_00_00_27_21.jpg"/>
            <div>
            <h3>Wiera Muchina</h3>
            <h5>1889-1853</h5>
            </div>
        </Biogram>
        </div>
        <Masonry>
    
     <ArtistImage width="33%">
            <img src="https://upload.wikimedia.org/wikipedia/commons/7/73/The_Worker_and_Kolkhoz_Woman.jpg" />
            <div>
                <h5>Robotnik i kołchoźnica</h5>
                <span>1937</span>
            </div>
        </ArtistImage>
     
     <ArtistImage width="30%">
            <img src="https://www.mos.ru/upload/newsfeed/newsfeed/gorki(120)(1).jpg" />
            <div>
                <h5>Monument to Maxim Gorky</h5>
                <span>1939</span>
            </div>
        </ArtistImage>
    
     <ArtistImage width="30%">
            <img src="https://russianlandmarks.files.wordpress.com/2016/01/tchaikovsky3.jpg" />
            <div>
                <h5>Tchaikovsky Monument</h5>
                <span>?</span>
            </div>
        </ArtistImage>
            </Masonry>
    </Container>
   </div>
   </>
)